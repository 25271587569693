import Image from 'next/legacy/image'
import Link from 'next/link'

import HeroImage from '../public/images/heroes/cables.png'

const Hero404 = () => (
  <div className="px-4 py-20 bg-solitude min-h-screen text-center">
    <h1 className="text-6xl font-bold pb-2">404</h1>
    <h2 className="text-2xl font-semibold text-gray-400 uppercase pb-2">
      Page not found
    </h2>
    <Image width={250} height={335.95} src={HeroImage} alt="Cables" />
    <p className="">
      {'This page does not exist. '}
      <Link href="/" className="text-blue-600 underline">
        Click here
      </Link>
      {' to return home.'}
    </p>
  </div>
)

export default Hero404
